import React, { useEffect } from 'react'
import { useParams } from 'react-router'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Hooks
import { useConfirmVaspEmail } from 'core/hooks/api'

// Styled Elements
import {
  Wrapper,
  InnerWrapper,
  LogoLink,
  Title,
  Error,
  FormWrapper,
  FormInnerWrapper,
  FormContent,
} from './VaspEmailConfirmation.elements'

// Components
import { LoadingTablePlaceholder } from 'views/components'

function VaspEmailConfirmation() {
  // Hooks
  const { confirmVaspEmail, confirmVaspEmailData, confirmVaspEmailError, isConfirmVaspEmailLoading } =
    useConfirmVaspEmail()
  const { hash } = useParams()

  useEffect(() => {
    confirmVaspEmail({ hash })
  }, [])
  // useEffect(() => {
  //   console.log(confirmVaspEmailData)
  // }, [confirmVaspEmailData])
  return (
    <Wrapper>
      <InnerWrapper>
        <FormWrapper>
          <FormInnerWrapper>
            <FormContent>
              <LogoLink>
                <OspreeLogoIcon />
              </LogoLink>
              {isConfirmVaspEmailLoading && !confirmVaspEmailData && <Title>Confirming Email</Title>}
              {isConfirmVaspEmailLoading && !confirmVaspEmailData && <LoadingTablePlaceholder />}
              {!isConfirmVaspEmailLoading && confirmVaspEmailData && <Title>Successfully confirmed email</Title>}
              {confirmVaspEmailError && <Error>An error occured in confirming email</Error>}
            </FormContent>
          </FormInnerWrapper>
        </FormWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

// Default Props
VaspEmailConfirmation.defaultProps = {}

// Proptypes Validation
VaspEmailConfirmation.propTypes = {}

export default VaspEmailConfirmation
