import React from 'react'

// Constants
import { EXTERNAL_URLS } from 'common/constants/apiUrls'

// Assets
import Logo from 'assets/images/ospreeBlackLarge.png'

// Styled Elements
import {
  Wrapper,
  InnerWrapper,
  LogoLink,
  Title,
  FormWrapper,
  FormRowWrapper,
  FormInnerWrapper,
  FormContent,
  FormFooterText,
  FormText,
  FormFooterLink,
} from './ResetPassword.elements'

// Components
import { Image } from 'views/components'
import { ResetPasswordForm } from 'views/layouts'

function ResetPassword() {
  return (
    <Wrapper>
      <InnerWrapper>
        <FormWrapper>
          <FormInnerWrapper>
            <FormContent>
              <LogoLink>
                <Image src={Logo} width={107} />
              </LogoLink>
              <Title>Reset Your Password</Title>
              <FormText>
                Enter the email address for your account and we&apos;ll send you a temporary link to reset your
                password.
              </FormText>
              <ResetPasswordForm />
              <FormRowWrapper>
                <FormFooterText>
                  <FormFooterLink href="/login">Return to sign in.</FormFooterLink>
                </FormFooterText>
                <FormFooterText>
                  Don&apos;t have an account?&nbsp;&nbsp;
                  <FormFooterLink href={EXTERNAL_URLS.GET_STARTED}>Sign up.</FormFooterLink>
                </FormFooterText>
              </FormRowWrapper>
            </FormContent>
          </FormInnerWrapper>
        </FormWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

// Default Props
ResetPassword.defaultProps = {}

// Proptypes Validation
ResetPassword.propTypes = {}

export default ResetPassword
