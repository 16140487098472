import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

// Store
import { actions } from 'core/store'

// Hooks
import { useGetUser, useUpdateProfile } from 'core/hooks/api'

// Styled Elements
import { Wrapper, EditBtnWrapper, DetailsContainer, FooterBtnWrapper } from './UserDetails.elements'

// Components
import { Container, Button } from 'views/components'

// layouts
import { UserDetailsForm, App, Header, Sidebar, Content, PageWrapper } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function UserDetails(props) {
  // Destructure
  const { actions } = props
  const { user_id } = useParams()

  // store actions
  const { setShowHeaderLoader, showAlert } = actions

  // refs
  const formRef = useRef()

  // hooks
  const { getUser, getUserData, isGetUserLoading } = useGetUser()
  const { updateProfile, isUpdateProfileLoading, isUpdateProfileSuccess } = useUpdateProfile()

  // state
  const [userDataState, setUserDataState] = useState()
  const [viewMode, setViewMode] = useState(true)

  // functions
  const fetchData = () => {
    getUser({ user_id })
  }
  const handleSave = () => {
    setViewMode(true)
    const newPayload = formRef.current.values
    updateProfile({ user_id, data: newPayload })
  }
  const handleCancel = () => {
    setViewMode(true)
    setUserDataState(getUserData)
  }
  const handleLoadingUpdateChange = () => {
    if (isUpdateProfileLoading) {
      setShowHeaderLoader(true)
    }
    if (!isUpdateProfileLoading) {
      setShowHeaderLoader(false)
      if (userDataState) {
        if (isUpdateProfileSuccess) {
          showAlert({ type: 'success', message: 'Successfully updated record' })
        } else if (!isUpdateProfileSuccess) {
          showAlert({ type: 'error', message: 'An error occured in updating record' })
        }
      }
    }
  }
  const handleLoadingUser = () => {
    if (isGetUserLoading) setShowHeaderLoader(true)
    if (!isGetUserLoading) setShowHeaderLoader(false)
  }

  // useEffect
  useEffect(() => fetchData(), [])
  useEffect(() => {
    if (getUserData) setUserDataState(getUserData)
  }, [getUserData])
  useEffect(() => handleLoadingUser(), [isGetUserLoading])
  useEffect(() => handleLoadingUpdateChange(), [isUpdateProfileLoading])

  return (
    <App>
      <Sidebar />
      <Header pageTitle="User Profile" />
      <Content>
        <PageWrapper>
          <Container>
            <Wrapper>
              {!isGetUserLoading && userDataState && (
                <DetailsContainer>
                  {viewMode === true && (
                    <EditBtnWrapper>
                      <Button color="primary" onClick={() => setViewMode(false)}>
                        Edit
                      </Button>
                    </EditBtnWrapper>
                  )}
                  <UserDetailsForm
                    // values={userDataState}
                    viewMode={viewMode}
                    formRef={formRef}
                    values={userDataState}
                  />
                  {viewMode === false && (
                    <FooterBtnWrapper>
                      <Button onClick={handleCancel} variant="outlined">
                        Cancel
                      </Button>
                      <Button onClick={handleSave}>save</Button>
                    </FooterBtnWrapper>
                  )}
                </DetailsContainer>
              )}
            </Wrapper>
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
UserDetails.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
UserDetails.propTypes = {
  filters: PropTypes.shape({
    userDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setUserDirectoryFilters: PropTypes.func,
    setIsIndividualUserDrawerOpen: PropTypes.func,
    setIsEntityUserDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
