
/*
* Licensed to the Apache Software Foundation (ASF) under one
* or more contributor license agreements.  See the NOTICE file
* distributed with this work for additional information
* regarding copyright ownership.  The ASF licenses this file
* to you under the Apache License, Version 2.0 (the
* "License"); you may not use this file except in compliance
* with the License.  You may obtain a copy of the License at
*
*   http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing,
* software distributed under the License is distributed on an
* "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
* KIND, either express or implied.  See the License for the
* specific language governing permissions and limitations
* under the License.
*/

var DataZoomModel = require("./DataZoomModel");

/*
* Licensed to the Apache Software Foundation (ASF) under one
* or more contributor license agreements.  See the NOTICE file
* distributed with this work for additional information
* regarding copyright ownership.  The ASF licenses this file
* to you under the Apache License, Version 2.0 (the
* "License"); you may not use this file except in compliance
* with the License.  You may obtain a copy of the License at
*
*   http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing,
* software distributed under the License is distributed on an
* "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
* KIND, either express or implied.  See the License for the
* specific language governing permissions and limitations
* under the License.
*/
var _default = DataZoomModel.extend({
  type: 'dataZoom.inside',

  /**
   * @protected
   */
  defaultOption: {
    disabled: false,
    // Whether disable this inside zoom.
    zoomLock: false,
    // Whether disable zoom but only pan.
    zoomOnMouseWheel: true,
    // Can be: true / false / 'shift' / 'ctrl' / 'alt'.
    moveOnMouseMove: true,
    // Can be: true / false / 'shift' / 'ctrl' / 'alt'.
    moveOnMouseWheel: false,
    // Can be: true / false / 'shift' / 'ctrl' / 'alt'.
    preventDefaultMouseMove: true
  }
});

module.exports = _default;