import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
// import PlusWhiteIcon from 'assets/images/plus-white'
// import UsersIcon from 'assets/images/users'
// import BriefCaseIcon from 'assets/images/briefcase'

// Store
import { actions } from 'core/store'

// Hooks
// import { useLocalStorage } from 'core/hooks/storage'
import { useGetTransactions } from 'core/hooks/api'

// Styled Elements
import { TableWrapper } from './TransactionsTable.elements'

// Views
import { AccordionTable, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

import { TransactionsTableItem, TransactionTableFilter } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function TransactionsTable(props) {
  // Destructure
  const { filters, ui, actions, transaction } = props

  // Store States
  const { transactionFilters } = filters
  const { scannedTransaction } = transaction
  const { isPageTableUpdated } = ui
  const {
    setIsPageTableUpdated,
    setTransactionFilters,
    showAlert,
    setScannedTransaction,
    setScannedTransactionDetails,
  } = actions

  // Hooks
  const { getTransactions, getTransactionsData, getTransactionsError, isGetTransactionsLoading } = useGetTransactions()

  // Local states
  const [transactions, setTransactions] = useState([])
  const [pagination, setPagination] = useState()
  const [openedTab, setOpenedTab] = useState()

  // Functions

  function handleScannedAddress() {
    const newTransaction = {
      ...scannedTransaction,
      ...scannedTransaction.risk_data,
      ...scannedTransaction.financial_data,
      events: null,
      risk_partner_desc: null,
      risk_score_desc: null,
    }
    const newTransactionList = [newTransaction]
    transactions.forEach((item) => {
      if (item.hash !== scannedTransaction.hash) {
        newTransactionList.push(item)
      }
    })
    // Changing it back to null
    setScannedTransaction(null)
    setTransactions(newTransactionList)
    setScannedTransactionDetails(scannedTransaction)
  }

  // UseEffects
  useEffect(() => getTransactions(transactionFilters), [transactionFilters])
  useEffect(() => {
    if (isPageTableUpdated) {
      getTransactions(transactionFilters)
      setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated])
  useEffect(() => {
    if (getTransactionsData) {
      setTransactions(getTransactionsData.items)
      setPagination(getTransactionsData.pagination)
    }
  }, [getTransactionsData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (getTransactionsError) showAlert({ type: 'error', message: 'An error occured in fetching transactions' })
  }, [getTransactionsError])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (scannedTransaction) handleScannedAddress()
  }, [scannedTransaction])
  return (
    <TableWrapper>
      <AccordionTable
        totalItems={pagination?.total && pagination.total}
        minWidth={900}
        filterComponents={<TransactionTableFilter />}
        tableFilters={transactionFilters}
        setTableFilters={setTransactionFilters}
      >
        {transactions.length > 0 &&
          !isGetTransactionsLoading &&
          transactions.map((data) => (
            <TransactionsTableItem openedTab={openedTab} setOpenedTab={setOpenedTab} key={data.id} data={data} />
          ))}
        {isGetTransactionsLoading && <LoadingTablePlaceholder />}
        {transactions.length < 1 && !isGetTransactionsLoading && (
          <EmptyTablePlaceholder setFilter={setTransactionFilters} />
        )}
      </AccordionTable>
    </TableWrapper>
  )
}

// Default Props
TransactionsTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
TransactionsTable.propTypes = {
  transaction: PropTypes.shape({
    scannedTransaction: PropTypes.instanceOf(Object),
  }),
  filters: PropTypes.shape({
    transactionFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setTransactionFilters: PropTypes.func,
    showAlert: PropTypes.func,
    setScannedTransaction: PropTypes.func,
    setScannedTransactionDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTable)
