import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import { TableWrapper } from './UsersTable.elements'

// Views
import { Table } from 'views/components'

import { UsersTableItem, UsersTableHeader } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function UsersTable() {
  // Destructure
  // const { ui, actions } = props

  // Store States
  // const { isPageTableUpdated } = ui
  // const { setIsPageTableUpdated, showAlert } = actions

  // Hooks
  const [currentUser] = useLocalStorage('userCredentials', {})

  // Local states
  const [users, setUsers] = useState([])
  useEffect(() => {
    setUsers([
      {
        email: currentUser.email,
        name: currentUser.name_first + currentUser.name_last,
        permissions: currentUser.role_desc,
      },
    ])
  }, [])
  return (
    <TableWrapper>
      <Table hidePagination tableFilters={{ size: 0, page: 0 }} minWidth={900}>
        <UsersTableHeader />
        {users.length > 0 && (
          <>
            {users.map((data) => (
              <UsersTableItem key={data.email} data={data} />
            ))}
          </>
        )}
        {/* ) : (
          <div>{isGetReportsLoading && <LoadingTablePlaceholder />}</div>
        )} */}
      </Table>
    </TableWrapper>
  )
}

// Default Props
UsersTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
UsersTable.propTypes = {
  filters: PropTypes.shape({
    reportsFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedAccount: PropTypes.func,
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setReportsFilters: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)
