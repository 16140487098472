import { authAxios } from 'common/utils/axios'

// Endpoint
import { PROFILE_ENDPOINTS } from 'common/constants/apiUrls'

export const GetUserProfile = async ({ user_id }) => {
  const response = await authAxios.get(PROFILE_ENDPOINTS.READ_PROFILE, {
    params: {
      user_id,
    },
  })

  const { data } = response

  return data
}

export const UpdateProfile = async ({ user_id, data }) => {
  const response = await authAxios.patch(`${PROFILE_ENDPOINTS.UPDATE_PROFILE}/${user_id}`, data)

  return response.data
}
