import styled from 'styled-components'

// Library Components
import { FormGroup } from 'reactstrap'

export const SelectWrapper = styled.div`
  width: 100%;
  min-height: 48px;
  margin-top: ${({ label }) => label && '-5px;'};
`

export const FormGroupWrapper = styled(FormGroup)`
  position: relative;

  // Overrides
  &.form-group {
    margin: 0px;
  }
`

export const Label = styled.label`
  position: relative;
  z-index: 1;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 12px;
  padding: 0 8px;
  transform: translate(15px, 15px);

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`

export const SelectInput = styled.select`
  display: none;
`

export const SelectOption = styled.option`
  color: ${({ theme }) => theme.colors.darkGray};
`

export const SelectInputLabel = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  transition: 0.4s;

  position: relative;
  // TODO: Use theme functions
  &:after {
    content: '';

    position: absolute;
    top: 42%;
    right: 15px;

    display: ${({ disabled }) => (disabled ? 'none' : 'inline-block')};
    margin-left: 0.255em;
    vertical-align: 0.255em;

    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: ${({ theme }) => theme.colors.darkGray};
  }

  min-height: 54px;
  height: fit-content;
  width: 100%;

  padding: 15px 20px;

  font-size: 16px;
  border-radius: 10px;
  font-weight: 400;
  box-shadow: none;

  border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};

  background-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.colors.white)};
  border-radius: ${({ open }) => (open ? '10px 10px 0 0' : '10px')};
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.07);
`

export const SelectInputOptionsContainer = styled.div`
  z-index: 3;
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.darkGray};

  padding: 10px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;

  font-size: 16px;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
  border-right: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
  border-radius: ${({ open }) => (open ? '0 0 10px 10px' : '10px')};
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.07);
  user-select: none;
`

export const SelectInputOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  gap: 10px;

  transition: 0.4s;

  border-radius: 8px;
  border: ${({ disabled }) => !disabled && '1px solid rgba(0, 0, 0, 0)'};
  color: ${(props) => (props.disabled ? props.theme.colors.gray : props.theme.colors.darkGray)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background: ${({ theme, disabled }) => !disabled && theme.colors.lightGray};
  }
`

export const SelectPlaceholder = styled.div`
  color: ${(props) => (props.disabled ? props.theme.colors.lightGray : props.theme.colors.darkGray)};
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Abbreviation = styled.div`
  color: ${({ theme }) => theme.colors.lightGray};
  min-width: 40px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const Text = styled.div`
  min-width: 40px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
