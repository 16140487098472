import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  countryListOptions: [],
  activityToRemove: {
    type: '',
    hash: '',
  },
  addressOrTransactionIdToAssign: {
    type: '',
    id: '',
    caseId: '',
  },
  createdAccount: null,
  createdRecipient: null,
  createdVasp: null,
  toBeUpdatedAccount: null,
  toBeUpdatedRecipient: null,
  toBeUpdatedVasp: null,
}

const store = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setCountryListOptions: (state, { payload }) => {
      console.trace()
      console.log('here')
      state.countryListOptions = payload
    },
    setActivityToRemove: (state, { payload }) => {
      state.activityToRemove = payload
    },
    setAddressOrTransactionIdToAssign: (state, { payload }) => {
      state.addressOrTransactionIdToAssign = payload
    },
    setCreatedAccount: (state, { payload }) => {
      state.createdAccount = payload || null
    },
    setCreatedRecipient: (state, { payload }) => {
      state.createdRecipient = payload || null
    },
    setCreatedVasp: (state, { payload }) => {
      state.createdVasp = payload || null
    },
    setToBeUpdatedAccount: (state, { payload }) => {
      state.toBeUpdatedAccount = payload || null
    },
    setToBeUpdatedRecipient: (state, { payload }) => {
      state.toBeUpdatedRecipient = payload || null
    },
    setToBeUpdatedVasp: (state, { payload }) => {
      state.toBeUpdatedVasp = payload || undefined
    },
  },
})

export default store
