import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import moment from 'moment'

// Route Configurations
import Routes from 'common/config/route'

// Global Components
import GlobalComponents from 'views/global'

function Routing() {
  /// Check if user is logged in
  const userCredentials = JSON.parse(localStorage.getItem('userCredentials'))
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'))

  // Checking if Access token has expired
  if (userCredentials) {
    if (Object.keys(userCredentials).length !== 0) {
      const expiryDate = new Date(userCredentials.expiry).getTime()
      const now = moment()
      const expirationDate = moment(expiryDate)

      // Compare if the current date is before the expiration date
      if (!now.isBefore(expirationDate)) {
        logout()
      }

      const oneHour = 1 * 60 * 60 * 1000
      setTimeout(logout, oneHour)
      // const timeDiff = expirationDate.diff(now)
      // setTimeout(logout, timeDiff)
    }
  }

  /**
   * Validates if current authenticated user is authorized to view a route
   * @param  {} roles
   */
  const isUserAuthorized = (roles) => {
    // If route has role restriction, check if user role is authorized
    if (roles && roles.length) roles.some((role) => role === userCredentials.role)

    // Else route has no restriction give user access
    return true
  }

  /**
   * Conditionally selects route/component to render based on authentication or authorization
   * @param  {} isRouteProtected
   * @param  {} routeRoleAccess
   * @param  {} component
   */
  const renderRouteComponent = ({ isRouteProtected, isRouteAdmin, routeRoleAccess, component, path }) => {
    const emptyPath = path === undefined || path === '/'

    if (!isRouteProtected) {
      // Render public routes
      if ((!isLoggedIn || !userCredentials?.access_token) && emptyPath) {
        return (
          <>
            <Redirect to="/login" />
            <GlobalComponents />
          </>
        )
      }
      if (!isLoggedIn || !userCredentials?.access_token) {
        return (
          <>
            {React.createElement(component)}
            <GlobalComponents />
          </>
        )
      }
      // Redirect to home page if logged in user access public routes
      if (isLoggedIn && userCredentials?.access_token && emptyPath) {
        return (
          <>
            <Redirect to="/blockchain-analytics" />
            <GlobalComponents />
          </>
        )
      }
      if (isLoggedIn && userCredentials?.access_token) {
        return (
          <>
            <Redirect to="/blockchain-analytics" />
            <GlobalComponents />
          </>
        )
      }
    }

    if (isRouteProtected) {
      // Redirect to login page if credentials are missing or not logged in
      if (!userCredentials?.access_token || !isLoggedIn) {
        return (
          <>
            <Redirect to="/login" />
            <GlobalComponents />
          </>
        )
      }
      if ((!userCredentials?.access_token || !isLoggedIn) && emptyPath) {
        return (
          <>
            <Redirect to="/login" />
            <GlobalComponents />
          </>
        )
      }
      // Render protected routes
      if (userCredentials?.role_id !== 0 && isRouteAdmin) {
        return (
          <>
            <Redirect to="/blockchain-analytics" />
            <GlobalComponents />
          </>
        )
      }
      if (userCredentials?.role_id !== 0 && isRouteAdmin && emptyPath) {
        return (
          <>
            <Redirect to="/blockchain-analytics" />
            <GlobalComponents />
          </>
        )
      }
      if (userCredentials?.access_token && isUserAuthorized(routeRoleAccess) && isLoggedIn) {
        return (
          <>
            {React.createElement(component)}
            <GlobalComponents />
          </>
        )
      }
    }

    // Render not found route
    if (isRouteProtected === undefined || !path) {
      return <>{React.createElement(component)}</>
    }
  }

  return (
    <BrowserRouter>
      <Switch>
        {Routes.map(({ isRouteProtected, isRouteAdmin, exact, roleAccess, routing: { path, component } }) => (
          <Route
            exact={exact}
            path={path}
            key={uuid()}
            render={() => renderRouteComponent({ isRouteProtected, isRouteAdmin, roleAccess, component, path })}
          />
        ))}
      </Switch>
    </BrowserRouter>
  )
}
export default Routing

function logout() {
  localStorage.removeItem('userCredentials')
  return window.location.reload()
}
