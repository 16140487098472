import React, { useEffect } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
// import jwt from 'jwt-decode'

// Constants
// import { TIMEZONES } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useSignupVasp } from 'core/hooks/api'

// Styled Elements
import {
  FormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  // FormInputTimezoneGroupItem,
  FormTitle,
} from './VaspSignupForm.elements'

// Views
import {
  Form,
  FormTextField,
  // FormSelect,
  Button,
} from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function VaspSignupForm(props) {
  // Destructure
  const { formRef, actions } = props

  // Store Actions
  const { showAlert } = actions

  // Hooks
  const { signupVasp, signupVaspData, signupVaspError, isSignupVaspLoading } = useSignupVasp()
  const { jwt: jwttoken } = useParams()
  // const token = jwt(jwttoken)

  // Variables
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirm_password: Yup.string().when('password', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
    }),
    phone: Yup.string().optional(),
    name_first: Yup.string().required('First Name is required'),
    name_last: Yup.string().required('Last Name is required'),
    timezone_code: Yup.string().required('Timezone is required'),
    jwt: Yup.string(),
  })
  const date = new Date()
  const offset = date.getTimezoneOffset()
  const initialFormValues = {
    email: '',
    password: '',
    phone: '',
    name_first: '',
    name_last: '',
    timezone_code: offset,
    jwt: jwttoken,
  }

  // Functions
  const handleOnSubmit = (values) => {
    signupVasp(values)
  }

  // UseEffects
  useEffect(() => {
    if (signupVaspError) showAlert({ type: 'error', message: 'An error occured in registering' })
  }, [signupVaspError])
  return (
    <FormWrapper>
      {signupVaspData && !signupVaspError && (
        <FormTitle>
          Successfully sent your registration application. Registration is a 2 stage process and may take 2-3 business
          days to complete.
        </FormTitle>
      )}
      {!signupVaspData && !signupVaspError && (
        <Form
          initialValues={initialFormValues}
          formRef={formRef}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormTextField label="Email" type="email" name="email" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="First Name" name="name_first" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Last Name" name="name_last" />
            </FormInputGroupItem>
            {/* <FormInputTimezoneGroupItem>
              <FormSelect label="Timezone" name="timezone_code" options={TIMEZONES} />
            </FormInputTimezoneGroupItem> */}
            <FormInputGroupItem>
              <FormTextField label="Phone Number" name="phone" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Password" type="password" name="password" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Confirm Password" type="password" name="confirm_password" />
            </FormInputGroupItem>
          </FormGroupWrapper>

          <Button fullWidth type="submit" disabled={isSignupVaspLoading}>
            {isSignupVaspLoading ? 'Submitting...' : 'Signup'}
          </Button>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
VaspSignupForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    external_id: '',
    email: '',
    name_first: '',
    name_last: '',
    incorporation_country: '',
    inc_date: '',
    primary_activity_id: 0,

    created: '',
    last_modified: '',

    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    timezone_code: '',
  },
}

// Proptypes Validation
VaspSignupForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    TIMEZONES: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.func,
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    external_id: PropTypes.string,
    email: PropTypes.string,
    name_first: PropTypes.string,
    name_last: PropTypes.string,
    incorporation_country: PropTypes.string,
    inc_date: PropTypes.string,
    primary_activity_id: PropTypes.number,

    created: PropTypes.string,
    last_modified: PropTypes.string,

    address_street: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    timezone_code: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(VaspSignupForm)
