"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _antd = require("antd");

var confirm = _antd.Modal.confirm;

var _default = function _default(options) {
  confirm(options);
};

exports["default"] = _default;