/* eslint-disable react/no-unstable-nested-components */
import React from 'react'

// Styled Elements
import { TableButtonWrapper, TableRowWrapper, TableRowContainer, TableText } from './UsersTableHeader.elements'

function UsersTableHeader() {
  return (
    <TableButtonWrapper>
      <TableRowWrapper style={{ gridTemplateColumns: '1fr 1fr 2fr' }}>
        <TableRowContainer>
          <TableText>Email</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Name</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Premissions</TableText>
        </TableRowContainer>
      </TableRowWrapper>
    </TableButtonWrapper>
  )
}

// Default Props
UsersTableHeader.defaultProps = {}

// Proptypes Validation
UsersTableHeader.propTypes = {}
export default UsersTableHeader
