import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import { CloseIcon } from 'assets/images'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalBodyWrapper } from './InfoModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, LoadingTablePlaceholder } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function InfoModal(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isInfoModalOpen, infoModalDetails } = ui
  const { title, content, loading } = infoModalDetails

  // Store Actions
  const { toggleInfoModal, setInfoModalDetails } = actions

  // Functions
  const toggleModal = () => {
    setInfoModalDetails({
      title: '',
      content: '',
      loading: false,
    })
    toggleInfoModal()
  }

  return (
    <Modal isOpen={isInfoModalOpen} toggle={!loading && toggleModal}>
      <ModalHeader toggle={toggleModal} close={!loading && <CloseIcon />}>
        {title}
      </ModalHeader>
      <ModalBody>
        <ModalBodyWrapper>{content}</ModalBodyWrapper>
        {loading && <LoadingTablePlaceholder />}
      </ModalBody>
    </Modal>
  )
}

// Default Props
InfoModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
InfoModal.propTypes = {
  ui: PropTypes.shape({
    isInfoModalOpen: PropTypes.bool,
    infoModalDetails: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      loading: PropTypes.bool,
    }),
  }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    toggleInfoModal: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoModal)
