import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { ACCOUNT_TYPE_OPTIONS } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Styled Elements
import { FormWrapper, FormInputGroupItem, FormGroupWrapper, FormTitle } from './UserForm.elements'

// Views
import { Form, FormTextField, FormSelect } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function UserForm(props) {
  // Destructure
  const { values, formRef } = props

  // Internal State
  const [initialData, setInitialData] = useState()

  // Variables
  const validationSchema = Yup.object().shape({
    email: Yup.string().nullable(),
    name_first: Yup.string().nullable(),
    name_last: Yup.string().nullable(),
    permissions: Yup.string().nullable(),
  })

  // UseEffects
  useEffect(() => {
    setInitialData(values)
  }, [values])
  return (
    <FormWrapper>
      {initialData && (
        <Form formRef={formRef} initialValues={initialData} validationSchema={validationSchema}>
          <FormTitle>Create User</FormTitle>
          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormTextField label="Email" type="email" name="email" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="First Name" name="name_first" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Last Name" name="name_last" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSelect label="Permissions" name="permissions" options={ACCOUNT_TYPE_OPTIONS} />
            </FormInputGroupItem>
          </FormGroupWrapper>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
UserForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    external_id: '',
    account_type_id: 0,
    status_id: 0,
    email: '',
    primary_activity_code: 0,
    nationality_code: '',
    name_first: '',
    name_middle: '',
    name_last: '',
    dob: '',

    created: '',
    last_modified: '',

    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    address_country_code: '',
  },
}

// Proptypes Validation
UserForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    COUNTRIES_LIST: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.shape({}),
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    external_id: PropTypes.string,
    account_type_id: PropTypes.number,
    status_id: PropTypes.number,
    email: PropTypes.string,
    primary_activity_code: PropTypes.number,
    nationality_code: PropTypes.number,
    name_first: PropTypes.string,
    name_middle: PropTypes.string,
    name_last: PropTypes.string,
    dob: PropTypes.string,

    created: PropTypes.string,
    last_modified: PropTypes.string,

    address_street: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    address_country_code: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
