import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import * as React from 'react';
import CascaderContext from '../context';
import { useBaseProps } from 'rc-select';
/**
 * Control the active open options path.
 */

export default (function () {
  var _useBaseProps = useBaseProps(),
      multiple = _useBaseProps.multiple,
      open = _useBaseProps.open;

  var _React$useContext = React.useContext(CascaderContext),
      values = _React$useContext.values; // Record current dropdown active options
  // This also control the open status


  var _React$useState = React.useState([]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      activeValueCells = _React$useState2[0],
      setActiveValueCells = _React$useState2[1];

  React.useEffect(function () {
    if (open && !multiple) {
      var firstValueCells = values[0];
      setActiveValueCells(firstValueCells || []);
    }
  },
  /* eslint-disable react-hooks/exhaustive-deps */
  [open]);
  return [activeValueCells, setActiveValueCells];
});