import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Constants
import {
  // ADDRESS_REGEX_OPTIONS, ADDRESS_CHAIN_OPTIONS_MAPPING,
  TRANSACTION_CHAIN_OPTIONS,
  INTEGRATION_PARTNERS_ID,
} from 'common/constants/formOptions'

// Hooks
import {
  useCreateOrUpdateTransaction,
  // useGetTransactionChainRecommendation
} from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  Wrapper,
  SearchBarWrapper,
  SearchInputWrapper,
  SearchBarContainer,
  Backdrop,
  // Title,
  CloseIconWrapper,
  ChainChoiceWrapper,
  ChainChoice,
} from './TransactionsSearchBar.elements'

// Views
import { TextField } from 'views/components'

// Assets
import { ScanIcon, CloseIcon } from 'assets/images'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function TransactionsSearchBar(props) {
  // props
  const { actions } = props

  // destructure
  const { showAlert, setScannedTransaction, toggleInfoModal, setInfoModalDetails } = actions

  // local states
  const [isActive, setIsActive] = useState(false)
  const [transactionInput, setTransactionInput] = useState('')
  const [chain, setChain] = useState('')
  // const [matchedChains, setMatchedChains] = useState([])
  // const [otherChains, setOtherChains] = useState([])

  // hooks
  const {
    createOrUpdateTransaction,
    createOrUpdateTransactionData,
    createOrUpdateTransactionError,
    isCreateOrUpdateTransactionLoading,
  } = useCreateOrUpdateTransaction()
  // const {
  //   getTransactionChainRecommendation,
  //   getTransactionChainRecommendationData,
  //   // getTransactionChainRecommendationError,
  //   // isGetTransactionChainRecommendationLoading,
  // } = useGetTransactionChainRecommendation()
  const [userCredentials] = useLocalStorage('userCredentials', {})

  // Functions
  function handleSubmit() {
    createOrUpdateTransaction({
      transaction: transactionInput,
      chain,
      name_first: userCredentials.name_first,
      name_last: userCredentials.name_last,
      integration_id: INTEGRATION_PARTNERS_ID[chain],
    })
    setTransactionInput('')
    setChain('')
  }
  // function handleChainRecommendation(data) {
  //   const currentMatchedChains = []
  //   if (data?.length > 0) {
  //     data.map((item) => {
  //       currentMatchedChains.push({ disabled: false, value: item, label: item })
  //     })
  //   }
  //   if (currentMatchedChains.length === 1) {
  //     if (currentMatchedChains[0]?.value === 'near') {
  //       return setMatchedChains([])
  //     }
  //   }
  //   if (currentMatchedChains.length < 1) return setMatchedChains([])
  //   if (currentMatchedChains.length > 1) return setMatchedChains(currentMatchedChains)
  // }

  // console.log(matchedChains)
  function handleTransactionChange(inputValue) {
    setTransactionInput(inputValue)
    // getTransactionChainRecommendation({ transaction: inputValue, chain })
    // handleChainMatch(inputValue)
  }

  useEffect(() => chain && handleSubmit(), [chain])
  // useEffect(() => {
  //   if (getTransactionChainRecommendationData) handleChainRecommendation(getTransactionChainRecommendationData)
  // }, [getTransactionChainRecommendationData])
  useEffect(() => !isActive && setTransactionInput(''), [isActive])
  useEffect(() => {
    if (createOrUpdateTransactionData) {
      setScannedTransaction(createOrUpdateTransactionData)
      setIsActive(false)
    }
  }, [createOrUpdateTransactionData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (createOrUpdateTransactionError) {
      showAlert({ type: 'error', message: 'An error occured in screening transaction' })
    }
  }, [createOrUpdateTransactionError])
  useEffect(() => {
    if (isCreateOrUpdateTransactionLoading) {
      toggleInfoModal(true)
      setInfoModalDetails({
        title: 'Scanning Transaction',
        content: 'This process may take over 60 seconds, depending on the volume of data.',
        loading: true,
      })
    } else {
      toggleInfoModal(false)
      setInfoModalDetails()
    }
  }, [isCreateOrUpdateTransactionLoading])
  return (
    <Wrapper>
      <SearchBarWrapper isActive={isActive}>
        <SearchBarContainer isActive={isActive}>
          <SearchInputWrapper isActive={isActive}>
            {isActive && (
              <CloseIconWrapper onClick={() => setIsActive(false)}>
                <CloseIcon />
              </CloseIconWrapper>
            )}
            <ScanIcon />
            <TextField
              placeholder={isCreateOrUpdateTransactionLoading ? 'loading...' : !isActive && 'Scan a transaction hash…'}
              onClick={() => {
                setIsActive(true)
              }}
              value={transactionInput}
              onChange={(e) => handleTransactionChange(e.currentTarget.value)}
            />
          </SearchInputWrapper>
          {transactionInput && isActive && !isCreateOrUpdateTransactionLoading && (
            <ChainChoiceWrapper isActive={isActive}>
              {/* {matchedChains?.length < 1 && (
                <Title>No chain has been identified for the provided transaction hash.</Title>
              )} */}
              {TRANSACTION_CHAIN_OPTIONS &&
                TRANSACTION_CHAIN_OPTIONS.map((item) => (
                  <ChainChoice
                    key={item.value}
                    style={{ opacity: item.disabled && 0.5, cursor: item.disabled && 'default' }}
                    onClick={() => !item.disabled && setChain(item.value)}
                    disabled={item.disabled}
                  >
                    {item.label}
                  </ChainChoice>
                ))}
              {/* {matchedChains &&
                matchedChains.map((item) => (
                  <ChainChoice
                    key={item.value}
                    style={{ opacity: item.disabled && 0.5, cursor: item.disabled && 'default' }}
                    onClick={() => !item.disabled && setChain(item.value)}
                    disabled={item.disabled}
                  >
                    {item.label}
                  </ChainChoice>
                ))} */}
              {/* <Title>Other Chain Options: </Title>
          {otherChains &&
            otherChains.map((item) => (
              <ChainChoice
                key={item.value}
                style={{ opacity: item.disabled && 0.5, cursor: item.disabled && 'default' }}
                onClick={() => !item.disabled && setChain(item.value)}
              >
                {item.label}
              </ChainChoice>
            ))} */}
            </ChainChoiceWrapper>
          )}
        </SearchBarContainer>
      </SearchBarWrapper>
      <Backdrop
        onClick={() => setIsActive(false)}
        style={{ zIndex: isActive ? '5' : '-1', opacity: isActive ? '0.5' : '0' }}
      />
    </Wrapper>
  )
}

// Default Props
TransactionsSearchBar.defaultProps = {
  actions: {},
}

// Proptypes Validation
TransactionsSearchBar.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setScannedTransaction: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsSearchBar)
