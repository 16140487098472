import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/close'

// Store
import { actions } from 'core/store'

// Styled Elements
import { DrawerWrapper, DrawerCloseIcon } from '../../DirectoryDrawers/DirectoryDrawers.elements'

// Views
import { Drawer, Button } from 'views/components'

// Layouts
import { UserForm } from 'views/layouts'
import { useRef } from 'react'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function UserDrawer(props) {
  // Destructure
  const { ui, actions } = props

  //  Store State
  const { isUserDrawerOpen } = ui

  // Store Actions
  const { setIsUserDrawerOpen } = actions

  //  Refs
  const formRef = useRef()

  // Functions
  const handleButtonClickSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm()
    }
  }

  const handleCloseDrawer = () => {
    setIsUserDrawerOpen()
  }

  return (
    <Drawer open={isUserDrawerOpen} anchor="right" onClose={handleCloseDrawer}>
      <DrawerWrapper>
        <DrawerCloseIcon onClick={handleCloseDrawer}>
          <CloseIcon />
        </DrawerCloseIcon>
        <UserForm formRef={formRef} />

        <Button onClick={handleButtonClickSubmit}>Create User</Button>
      </DrawerWrapper>
    </Drawer>
  )
}

// Default Props
UserDrawer.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
UserDrawer.propTypes = {
  ui: PropTypes.shape({ isUserDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({ setIsUserDrawerOpen: PropTypes.func }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDrawer)
