import styled from 'styled-components'

export const ResetPasswordFormWrapper = styled.div`
  & button {
    min-height: 56px;
  }
`

export const ResetPasswordFormLabel = styled.label``

export const FormInputGroupWrapper = styled.div`
  margin: 0px 0px 60px 0px;
`

export const FormInputGroupItem = styled.div`
  margin: 0px 0px 20px 0px;
`
