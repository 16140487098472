import React from 'react'

import {
  Alerts,
  AppSidebarDrawer,
  // AssignToCaseDrawer,
  DeleteAddressModal,
  DeleteAddressNoteModal,
  DeleteTransactionModal,
  // CasesManagementFilterModal,
  UploadCaseModal,
  ChooseKYCUploadModal,
  AddressHistoryModal,
  ReportAddressModal,
  ReportTransactionModal,
  InfoModal,
  NotifyVaspModal,
  UpdateTransferModal,
  TransactionDetailsModal,
  ExploreAccountModal,
  // CreateIndividualCaseDrawer,
  // CreateEntityCaseDrawer,
  IndividualAccountDrawer,
  IndividualRecipientDrawer,
  EntityAccountDrawer,
  EntityRecipientDrawer,
  VaspDrawer,
  TransferDrawer,
  LinkAccountDrawer,
  LinkAccountTransactionDrawer,
  UserDrawer,
} from 'views/layouts'

function Global() {
  return (
    <>
      {/* Alerts */}
      <Alerts />

      {/* Drawers */}
      <AppSidebarDrawer />
      {/* <AssignToCaseDrawer /> */}
      {/* <CreateIndividualCaseDrawer /> */}
      {/* <CreateEntityCaseDrawer /> */}
      <IndividualAccountDrawer />
      <IndividualRecipientDrawer />
      <EntityAccountDrawer />
      <EntityRecipientDrawer />
      <VaspDrawer />
      <TransferDrawer />
      <LinkAccountDrawer />
      <LinkAccountTransactionDrawer />
      <UserDrawer />

      {/* Modals */}
      <DeleteAddressModal />
      <DeleteTransactionModal />
      <InfoModal />
      <DeleteAddressNoteModal />
      {/* <CasesManagementFilterModal /> */}
      <UploadCaseModal />
      <ChooseKYCUploadModal />
      <AddressHistoryModal />
      <ReportAddressModal />
      <ReportTransactionModal />
      <NotifyVaspModal />
      <UpdateTransferModal />
      <TransactionDetailsModal />
      <ExploreAccountModal />
    </>
  )
}

export default Global
