import styled from 'styled-components'

export const TransferFormWrapper = styled.div`
  height: 100%;
`

export const FormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const FormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 20px 0;
`

export const FormLabel = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
  margin-top: 30px;

  &#selected-account {
    margin-bottom: -15px;
  }
`

export const AmountWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;
  border-radius: 4px;
  cursor: default;
  &:hover {
    #ConversionModal {
      display: block;
    }
    #ConversionModal:hover {
      display: block;
    }
  }
  & a {
    color: ${({ theme }) => theme.colors.blue};
  }
`
export const ModalWrapper = styled.div`
  display: none;
  position: relative;
`
export const ModalContainer = styled.div`
  min-width: 200px !important;
  z-index: 2;
  position: absolute;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 8px;
`
export const ModalTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  padding: 8px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  text-align: center;
`
export const ModalContent = styled.div`
  padding: 8px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`
export const ModalRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
`
export const ModalBody = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const FormInputGroupItem = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 140px;
  align-items: center;
  gap: 10px;
  width: 100%;

  background-color: transparent;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.lightGray};

  & input {
    background-color: transparent;
    border: none;
    padding: 15px 20px 45px 15px;
    border-radius: 0;
  }
  & > div > div > div {
    & > div {
      font-size: 16px;
    }
  }
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  & input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`
export const SubText = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const AssetContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  & svg {
    height: 35px !important;
    width: 35px !important;
  }
`
