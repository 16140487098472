import moment from 'moment'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'
import { SUPPORTED_CHAIN_SYMBOL_MAPPING } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  TransferFormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  FormWrapper,
  FormLabel,
  OptionLabel,
} from './RecipientTransferForm.elements'

// Hooks
import { useSearchRecipient, useFetchRecipient, useGetAddressesLinked } from 'core/hooks/api'

// Views
import { TextField, Button, Search, Select } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function RecipientTransferForm(props) {
  // Destructure
  const { formData, setFormData, actions } = props

  // Store Actions
  const {
    setIsIndividualRecipientDrawerOpen,
    setIsEntityRecipientDrawerOpen,
    setShowHeaderLoader,
    setToBeUpdatedRecipient,
  } = actions

  // hooks
  const { searchRecipient, recipientSearchData, isRecipientSearchLoading } = useSearchRecipient()
  const { getRecipient, recipientData, isRecipientLoading } = useFetchRecipient()
  const { getAddressesLinked, getAddressesLinkedData, isGetAddressesLinkedLoading } = useGetAddressesLinked()

  // Internal State
  const [searchResults, setSearchResults] = useState([])
  const [addresses, setAddresses] = useState()
  const [addressesMap, setAddressesMap] = useState()

  // Constants
  const filter = {
    sort: '-last_modified',
    page: 1,
    size: 100,
    start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
    end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  }

  // functions
  const handleTransactionChange = (value) => {
    setFormData({ ...formData, transfer: { ...formData.transfer, ...value } })
  }
  const handleRecipientSearch = debounce((value) => {
    if (!value) return setSearchResults([])
    if (value) return searchRecipient({ search_term: value })
  }, 500)
  const handleOnAutoCompleteSelect = (value) => {
    getRecipient({ account_id: value })
  }
  function formatAddressOptions(items) {
    if (items) {
      const newArr = []
      const newMap = {}
      items.forEach((item) => {
        newMap[item?.address] = item
        newArr.push({
          value: item?.address,
          label: item?.address,
          data: item,
        })
      })
      newArr.push({ value: null, label: 'None' })
      setAddressesMap(newMap)
      setAddresses(newArr)
    }
  }

  // useEffects
  useEffect(() => {
    if (recipientData) {
      setFormData({
        ...formData,
        recipient: { ...formData.recipient, ...recipientData },
        transfer: { ...formData.transfer, out_hash_address: null, selected_recipient_address: null },
      })
      getAddressesLinked({
        ...filter,
        chain: SUPPORTED_CHAIN_SYMBOL_MAPPING?.[formData?.transfer?.asset_code],
        link_id: recipientData.id,
      })
      setAddresses([])
      setAddressesMap()
    }
  }, [recipientData])
  useEffect(() => {
    if (getAddressesLinkedData?.items) {
      formatAddressOptions(getAddressesLinkedData.items)
    }
  }, [getAddressesLinkedData])
  useEffect(() => {
    if (recipientSearchData) {
      setSearchResults(() =>
        recipientSearchData.map(({ reference_id, display_name, id }) => ({
          label: reference_id,
          subLabel: display_name,
          value: id,
        }))
      )
    }
    if (!recipientSearchData) setSearchResults([])
  }, [recipientSearchData])
  useEffect(() => {
    if (isRecipientLoading) {
      setShowHeaderLoader(true)
      setFormData({ ...formData, recipient: null })
    }
    if (!isRecipientLoading) setShowHeaderLoader(false)
  }, [isRecipientLoading])

  return (
    <TransferFormWrapper>
      <FormWrapper>
        <FormGroupWrapper>
          <FormInputGroupItem>
            <Search
              placeholder={formData?.recipient ? formData?.recipient?.reference_id : 'Search for recipient'}
              onInputChange={handleRecipientSearch}
              options={searchResults}
              setOptions={setSearchResults}
              onSelect={handleOnAutoCompleteSelect}
              isLoading={isRecipientSearchLoading}
              bottomOptions={
                <FormGroupWrapper>
                  <OptionLabel>Create New Recipient</OptionLabel>
                  <FormInputGroupItem>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setToBeUpdatedRecipient(null)
                        setIsIndividualRecipientDrawerOpen(true)
                      }}
                    >
                      Individual
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setToBeUpdatedRecipient(null)
                        setIsEntityRecipientDrawerOpen(true)
                      }}
                    >
                      Business
                    </Button>
                  </FormInputGroupItem>
                </FormGroupWrapper>
              }
            />
          </FormInputGroupItem>
          <FormInputGroupItem>
            {isGetAddressesLinkedLoading && <FormLabel>Loading...</FormLabel>}
            {!isGetAddressesLinkedLoading && addresses && (
              <Select
                key={formData.recipient}
                longValue={false}
                placeholder={addresses ? 'Address Hash' : "Recipient isn't linked with any address"}
                label={addresses && 'Select address'}
                onChange={(v) => {
                  if (addressesMap[v]) {
                    handleTransactionChange({ out_hash_address: v, selected_recipient_address: addressesMap[v] })
                  }
                }}
                value={formData.transfer.out_hash_address ? formData.transfer.out_hash_address : null}
                options={addresses}
              />
            )}
          </FormInputGroupItem>

          {formData.recipient && !isRecipientLoading && (
            <>
              <FormLabel>Selected Recipient</FormLabel>
              <FormInputGroupItem>
                <TextField label="External ID" disabled value={formData.recipient.reference_id} />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField label="Recipient Type" disabled value={formData.recipient.account_type_desc} />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField label="Name" disabled value={formData.recipient.display_name} />
              </FormInputGroupItem>
            </>
          )}
          {formData?.transfer?.selected_recipient_address && !isGetAddressesLinkedLoading && (
            <>
              <FormLabel>Selected address</FormLabel>
              <FormInputGroupItem>
                <TextField label="Chain" disabled value={formData?.transfer?.selected_recipient_address?.chain} />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField label="Address" disabled value={formData?.transfer?.selected_recipient_address?.address} />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField
                  label="Risk Score"
                  disabled
                  value={formData?.transfer?.selected_recipient_address?.risk_ratio}
                />
              </FormInputGroupItem>
            </>
          )}
          {(isRecipientLoading || isGetAddressesLinkedLoading) && <FormLabel>Loading...</FormLabel>}
        </FormGroupWrapper>
      </FormWrapper>
    </TransferFormWrapper>
  )
}

// Default Props
RecipientTransferForm.defaultProps = {
  formData: {},
  setFormData: () => {},
  actions: {},
  form: {},
}

// Proptypes Validation
RecipientTransferForm.propTypes = {
  formData: PropTypes.shape({
    transfer: PropTypes.shape({
      out_hash_address: PropTypes.string,
      asset_code: PropTypes.string,
      selected_recipient_address: PropTypes.shape({
        chain: PropTypes.string,
        address: PropTypes.string,
        risk_ratio: PropTypes.number,
      }),
    }),
    recipient: PropTypes.shape({
      reference_id: PropTypes.string,
      account_type_desc: PropTypes.string,
      display_name: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setIsIndividualRecipientDrawerOpen: PropTypes.func,
    setIsEntityRecipientDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setToBeUpdatedRecipient: PropTypes.func,
  }),
  form: PropTypes.shape({
    createdRecipient: PropTypes.shape({}),
  }),
  setFormData: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientTransferForm)
