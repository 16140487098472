import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.white};
`

export const InnerWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
`
export const FormRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FormInnerWrapper = styled.div`
  max-width: 500px;
  width: 100%;
`

export const FormContent = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding: 40px;
  width: 100%;

  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`

export const LogoLink = styled.a`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

export const BrandWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 100vh;
`

export const BottomWrapper = styled.div`
  align-self: center;

  //TODO: Style Variable
  color: ${({ theme }) => theme.colors.white};
`

export const PrivacyPolicyLink = styled.a`
  display: block;
  margin: 20px 0px 0px 0px;
  width: 100%;

  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
  text-align: center;

  color: ${({ theme }) => theme.colors.darkGray};
`

export const Title = styled.h1`
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.darkGray};
  margin: 0 0 40px 0;
`
export const FormFooterText = styled.p`
  display: flex;
  justify-content: flex-end;
  margin: 5px 0 0 0;

  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: right;
`

export const FormText = styled.p`
  margin: 0;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: center;
  margin: 0 0 30px 0;
`

export const FormFooterLink = styled.a`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkGray};
`
