import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  SubHeaderWrapper,
  SubHeaderNavList,
  SubHeaderNavListItem,
  SubHeaderNavListLink,
} from './SettingsSubHeader.elements'

function SettingsSubHeader(props) {
  // Hooks
  const { page, setPage } = props

  return (
    <SubHeaderWrapper>
      <SubHeaderNavList>
        <SubHeaderNavListItem>
          <SubHeaderNavListLink
            className={page === 0 && 'active'}
            onClick={() => {
              setPage(0)
            }}
          >
            General
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>

        <SubHeaderNavListItem>
          <SubHeaderNavListLink
            className={page === 1 && 'active'}
            onClick={() => {
              setPage(1)
            }}
          >
            About
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>

        {/* <SubHeaderNavListItem>
          <SubHeaderNavListLink
            className={page === 2 && 'active'}
            onClick={() => {
              setPage(2)
            }}
          >
            Users
          </SubHeaderNavListLink>
        </SubHeaderNavListItem> */}
      </SubHeaderNavList>
    </SubHeaderWrapper>
  )
}

// Default Props
SettingsSubHeader.defaultProps = {
  page: 0,
  setPage: () => {},
}

// Proptypes Validation
SettingsSubHeader.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
}
export default SettingsSubHeader
