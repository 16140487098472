import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  padding: 30px 30px 35px 30px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
`
export const DetailsContainer = styled.div`
  width: 100%;
`
export const EditBtnWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  margin: 0 0 -40px 0;
`
export const FooterBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 40px;
`
