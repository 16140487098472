import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'

// Constants
import { TRAVELRULE_STATUS_FILTER, TRAVELRULE_ASSET_FILTER, TRAVELRULE_SORT } from 'common/constants/filters'

// Store
import { actions } from 'core/store'

// Hooks
import { useSearchTransfer } from 'core/hooks/api'

// Styled Elements
import { TableFilterFormWrapper } from '../Filter.elements'

// Components
import { SimpleSelect, Search } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function TravelRuleTableFilter(props) {
  // Destructure
  const { onTransferSearch, setIsTransferLoading, filters, actions } = props
  const { travelRuleFilters } = filters
  const { sort, transfer_status_id, asset_code } = travelRuleFilters

  // Store Actions
  const { setShowHeaderLoader, setTravelRuleFilters } = actions

  // Hooks
  const { transferSearchData, searchTransfer, isTransferSearchLoading } = useSearchTransfer()

  // Functions
  // Filter Functions
  const handleAssetSelect = async (value) => {
    await setTravelRuleFilters({ ...travelRuleFilters, asset_code: value })
  }
  const handleStatusSelect = async (value) => {
    await setTravelRuleFilters({
      ...travelRuleFilters,
      transfer_status_id: Number.isNaN(+value) ? undefined : +value,
    })
  }
  const handleSortSelect = async (value) => {
    await setTravelRuleFilters({ ...travelRuleFilters, sort: value })
  }
  // Search Functions
  const handleOnAutoCompleteInputChange = debounce((value) => {
    if (!value) {
      return onTransferSearch()
    }
    if (value) {
      return searchTransfer({ search_term: value })
    }
  }, 500)

  // useEffect
  useEffect(() => {
    if (transferSearchData) onTransferSearch(transferSearchData)
    if (!transferSearchData) onTransferSearch([])
  }, [transferSearchData])

  useEffect(() => {
    if (isTransferSearchLoading) {
      setIsTransferLoading(true)
      setShowHeaderLoader(true)
    } else if (!isTransferSearchLoading) {
      setIsTransferLoading(false)
      setShowHeaderLoader(false)
    }
  }, [isTransferSearchLoading])

  return (
    <TableFilterFormWrapper>
      <Search
        disabledOptions
        placeholder="Search Name..."
        onInputChange={handleOnAutoCompleteInputChange}
        isLoading={isTransferSearchLoading}
        filter={
          <>
            <SimpleSelect
              id="travelRuleAssetSort"
              label="Filter By: "
              placeholder="Asset"
              options={TRAVELRULE_ASSET_FILTER}
              onChange={handleAssetSelect}
              value={asset_code}
            />
            <SimpleSelect
              label="Filter By: "
              placeholder="Status"
              options={TRAVELRULE_STATUS_FILTER}
              onChange={handleStatusSelect}
              value={transfer_status_id}
            />
            <SimpleSelect
              id="travelRuleSort"
              label="Sort By: "
              placeholder="Amount or Date"
              options={TRAVELRULE_SORT}
              onChange={handleSortSelect}
              value={sort[1]}
            />
          </>
        }
      />
    </TableFilterFormWrapper>
  )
}

// Default Props
TravelRuleTableFilter.defaultProps = {
  onTransferSearch: () => {},
  filters: {},
  actions: {},
}

// Proptypes Validation
TravelRuleTableFilter.propTypes = {
  onTransferSearch: PropTypes.func,
  filters: PropTypes.shape({
    travelRuleFilters: PropTypes.shape({
      sort: PropTypes.string,
      asset_code: PropTypes.number,
      transfer_status_id: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setTravelRuleFilters: PropTypes.func,
  }),
  setIsTransferLoading: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelRuleTableFilter)
