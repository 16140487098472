import React, { useEffect } from 'react'
import { useParams } from 'react-router'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Hooks
import { useGetVaspInfo } from 'core/hooks/api'

// Styled Elements
import {
  VaspSignupWrapper,
  VaspSignupInnerWrapper,
  VaspSignupLogoLink,
  VaspSignupTitle,
  VaspSignupFormWrapper,
  VaspSignupFormInnerWrapper,
  VaspSignupFormContent,
} from './VaspSignup.elements'

// Components
import { VaspSignupForm } from 'views/layouts'

function VaspSignup() {
  // Hooks
  const { getVaspInfo, getVaspInfoData, isGetVaspInfoLoading } = useGetVaspInfo()
  const { jwt: jwttoken } = useParams()

  useEffect(() => {
    getVaspInfo(jwttoken)
  }, [])
  useEffect(() => {
    console.log(getVaspInfoData)
  }, [getVaspInfoData])
  return (
    <VaspSignupWrapper>
      <VaspSignupInnerWrapper>
        <VaspSignupFormWrapper>
          <VaspSignupFormInnerWrapper>
            <VaspSignupFormContent>
              <VaspSignupLogoLink>
                <OspreeLogoIcon />
              </VaspSignupLogoLink>
              <VaspSignupTitle>Sign up to Ospree</VaspSignupTitle>
              <VaspSignupForm />
            </VaspSignupFormContent>
          </VaspSignupFormInnerWrapper>
        </VaspSignupFormWrapper>
      </VaspSignupInnerWrapper>
    </VaspSignupWrapper>
  )
}

// Default Props
VaspSignup.defaultProps = {}

// Proptypes Validation
VaspSignup.propTypes = {}

export default VaspSignup
