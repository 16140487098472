import { authAxios } from 'common/utils/axios'

import { ADMIN_ENDPOINTS } from 'common/constants/apiUrls'

export const NotifyVasp = async ({ vasp_id }) => {
  const response = await authAxios.post(`${ADMIN_ENDPOINTS.NOTIFY_VASP}/${vasp_id}`, {
    vasp_id,
  })

  const { data } = response

  return data
}
