/* eslint-disable import/prefer-default-export, prefer-destructuring */
import { updateCSS } from "rc-util/es/Dom/dynamicCSS";
import { TinyColor } from '@ctrl/tinycolor';
import { generate } from '@ant-design/colors';
var dynamicStyleMark = "-ant-".concat(Date.now(), "-").concat(Math.random());
export function registerTheme(globalPrefixCls, theme) {
  var variables = {};

  var formatColor = function formatColor(color, updater) {
    var clone = color.clone();
    clone = (updater === null || updater === void 0 ? void 0 : updater(clone)) || clone;
    return clone.toRgbString();
  };

  var fillColor = function fillColor(colorVal, type) {
    var baseColor = new TinyColor(colorVal);
    var colorPalettes = generate(baseColor.toRgbString());
    variables["".concat(type, "-color")] = formatColor(baseColor);
    variables["".concat(type, "-color-disabled")] = colorPalettes[1];
    variables["".concat(type, "-color-hover")] = colorPalettes[4];
    variables["".concat(type, "-color-active")] = colorPalettes[7];
    variables["".concat(type, "-color-outline")] = baseColor.clone().setAlpha(0.2).toRgbString();
    variables["".concat(type, "-color-deprecated-bg")] = colorPalettes[1];
    variables["".concat(type, "-color-deprecated-border")] = colorPalettes[3];
  }; // ================ Primary Color ================


  if (theme.primaryColor) {
    fillColor(theme.primaryColor, 'primary');
    var primaryColor = new TinyColor(theme.primaryColor);
    var primaryColors = generate(primaryColor.toRgbString()); // Legacy - We should use semantic naming standard

    primaryColors.forEach(function (color, index) {
      variables["primary-".concat(index + 1)] = color;
    }); // Deprecated

    variables['primary-color-deprecated-l-35'] = formatColor(primaryColor, function (c) {
      return c.lighten(35);
    });
    variables['primary-color-deprecated-l-20'] = formatColor(primaryColor, function (c) {
      return c.lighten(20);
    });
    variables['primary-color-deprecated-t-20'] = formatColor(primaryColor, function (c) {
      return c.tint(20);
    });
    variables['primary-color-deprecated-t-50'] = formatColor(primaryColor, function (c) {
      return c.tint(50);
    });
    variables['primary-color-deprecated-f-12'] = formatColor(primaryColor, function (c) {
      return c.setAlpha(c.getAlpha() * 0.12);
    });
    var primaryActiveColor = new TinyColor(primaryColors[0]);
    variables['primary-color-active-deprecated-f-30'] = formatColor(primaryActiveColor, function (c) {
      return c.setAlpha(c.getAlpha() * 0.3);
    });
    variables['primary-color-active-deprecated-d-02'] = formatColor(primaryActiveColor, function (c) {
      return c.darken(2);
    });
  } // ================ Success Color ================


  if (theme.successColor) {
    fillColor(theme.successColor, 'success');
  } // ================ Warning Color ================


  if (theme.warningColor) {
    fillColor(theme.warningColor, 'warning');
  } // ================= Error Color =================


  if (theme.errorColor) {
    fillColor(theme.errorColor, 'error');
  } // ================= Info Color ==================


  if (theme.infoColor) {
    fillColor(theme.infoColor, 'info');
  } // Convert to css variables


  var cssList = Object.keys(variables).map(function (key) {
    return "--".concat(globalPrefixCls, "-").concat(key, ": ").concat(variables[key], ";");
  });
  updateCSS("\n  :root {\n    ".concat(cssList.join('\n'), "\n  }\n  "), "".concat(dynamicStyleMark, "-dynamic-theme"));
}