'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports['default'] = undefined;

var _echarts = require('echarts');

var _echarts2 = _interopRequireDefault(_echarts);

var _core = require('./core');

var _core2 = _interopRequireDefault(_core);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

// export the Component the echarts Object.
var EchartsReact = function (_EchartsReactCore) {
  _inherits(EchartsReact, _EchartsReactCore);

  function EchartsReact(props) {
    _classCallCheck(this, EchartsReact);

    var _this = _possibleConstructorReturn(this, (EchartsReact.__proto__ || Object.getPrototypeOf(EchartsReact)).call(this, props));

    _this.echartsLib = _echarts2['default'];
    return _this;
  }

  return EchartsReact;
}(_core2['default']);

exports['default'] = EchartsReact;