
/*
* Licensed to the Apache Software Foundation (ASF) under one
* or more contributor license agreements.  See the NOTICE file
* distributed with this work for additional information
* regarding copyright ownership.  The ASF licenses this file
* to you under the Apache License, Version 2.0 (the
* "License"); you may not use this file except in compliance
* with the License.  You may obtain a copy of the License at
*
*   http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing,
* software distributed under the License is distributed on an
* "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
* KIND, either express or implied.  See the License for the
* specific language governing permissions and limitations
* under the License.
*/

var echarts = require("../../echarts");

var zrUtil = require("zrender/lib/core/util");

/*
* Licensed to the Apache Software Foundation (ASF) under one
* or more contributor license agreements.  See the NOTICE file
* distributed with this work for additional information
* regarding copyright ownership.  The ASF licenses this file
* to you under the Apache License, Version 2.0 (the
* "License"); you may not use this file except in compliance
* with the License.  You may obtain a copy of the License at
*
*   http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing,
* software distributed under the License is distributed on an
* "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
* KIND, either express or implied.  See the License for the
* specific language governing permissions and limitations
* under the License.
*/
var _default = echarts.extendComponentView({
  type: 'marker',
  init: function () {
    /**
     * Markline grouped by series
     * @private
     * @type {module:zrender/core/util.HashMap}
     */
    this.markerGroupMap = zrUtil.createHashMap();
  },
  render: function (markerModel, ecModel, api) {
    var markerGroupMap = this.markerGroupMap;
    markerGroupMap.each(function (item) {
      item.__keep = false;
    });
    var markerModelKey = this.type + 'Model';
    ecModel.eachSeries(function (seriesModel) {
      var markerModel = seriesModel[markerModelKey];
      markerModel && this.renderSeries(seriesModel, markerModel, ecModel, api);
    }, this);
    markerGroupMap.each(function (item) {
      !item.__keep && this.group.remove(item.group);
    }, this);
  },
  renderSeries: function () {}
});

module.exports = _default;