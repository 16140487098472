import { authAxios } from 'common/utils/axios'

import { VASP_ENDPOINTS, LOCAL_URL } from 'common/constants/apiUrls'

import axios from 'axios'

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token

export const authAxiosSecondServer = axios.create({
  baseURL: LOCAL_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

export const GetAllVasps = async (values) => {
  const response = await authAxios.get(VASP_ENDPOINTS.GET_ALL_VASPS, {
    params: { ...values },
  })

  const { data } = response.data

  return data
}

export const GetVasp = async ({ vasp_id }) => {
  const response = await authAxios.get(`${VASP_ENDPOINTS.GET_VASP}${vasp_id}`)

  const { data } = response.data

  return data
}
export const SearchVasp = async ({ search_term }) => {
  const response = await authAxios.get(VASP_ENDPOINTS.SEARCH_VASP, {
    params: {
      search_term,
    },
  })

  const { data } = response.data

  return data
}

export const CreateVasp = async (values) => {
  const response = await authAxios.post(VASP_ENDPOINTS.CREATE_VASP, values)

  const { data } = response.data

  return data
}
export const UpdateVasp = async ({ vasp_id, values }) => {
  const response = await authAxios.patch(`${VASP_ENDPOINTS.UPDATE_VASP}/${vasp_id}`, { vasp_id, ...values })

  const { data } = response.data

  return data
}
