export default () => {
  const logoutUser = async () => {
    console.log('called')
    await localStorage.setItem('isLoggedIn', 'false')
    await localStorage.setItem('userCredentials', '{}')
    await localStorage.setItem('reportStrings', '[]')
    await localStorage.setItem('reportTitles', '[]')
    window.location.replace(window.location.origin)
  }

  return { logoutUser }
}
