import styled from 'styled-components'

export const TableButtonWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 50px;
  align-items: center;

  padding: 2px 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const TableRowWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  gap: 20px;
`
export const TableRowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  gap: 20px;
`
export const TableColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TableText = styled.div`
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  max-width: 250px;

  &:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`
export const TableTextTiny = styled.span`
  font-size: 8px;
  font-weight: 400;
`
export const TableSubText = styled.div`
  font-size: 10px;
  font-weight: 400;
`

export const TablePanelWrapper = styled.div``

export const StatusText = styled.div`
  padding: 5px 30px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  ${({ theme, status }) =>
    status === 'Completed' && `color: ${theme.colors.blue}; border: 1px solid ${theme.colors.blue};`}
  ${({ theme, status }) =>
    status === 'Pending' && `color: ${theme.colors.yellowGreen}; border: 1px solid ${theme.colors.yellowGreen};`}
`
