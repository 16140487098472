import React from 'react'
import PropTypes from 'prop-types'
// import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
// import { useLocalStorage } from 'core/hooks/storage'
// import { useUpdatePassword } from 'core/hooks/api'

// Styled Elements
import { ResetPasswordFormWrapper, FormInputGroupWrapper, FormInputGroupItem } from './ResetPasswordForm.elements'

// Views
import { Form, FormTextField, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function ResetPasswordForm(props) {
  // Destructure
  const { actions } = props

  // Store Actions
  const { showAlert } = actions

  // Variables
  const initialFormValues = {
    email: '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
  })

  // Hooks
  // const history = useHistory()
  // const [userCredentials] = useLocalStorage('userCredentials')

  // Functions
  const handleOnSubmit = (values) => {
    const { email } = values
  }

  // useEffect
  // useEffect(() => {
  //   if (updatePasswordData && !isUpdatePasswordError) {
  //     history.push('/')
  //   }
  // }, [updatePasswordData])

  // useEffect(() => {
  //   if (updatePasswordError) {
  //     showAlert({ type: 'error', message: updatePasswordError.response.data[0].message })
  //   }
  // }, [updatePasswordError])

  return (
    <ResetPasswordFormWrapper>
      <Form initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
        <FormInputGroupWrapper>
          <FormInputGroupItem>
            <FormTextField label="Email Address" type="email" name="email" />
          </FormInputGroupItem>
        </FormInputGroupWrapper>
        <Button fullWidth type="submit">
          {/* {isUpdatePasswordLoading || isUpdatePasswordSuccess ? 'Submitting...' : 'Continue'} */}
          Continue
        </Button>
      </Form>
    </ResetPasswordFormWrapper>
  )
}

// Default Props
ResetPasswordForm.defaultProps = {
  actions: {},
}

// Proptypes Validation
ResetPasswordForm.propTypes = {
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)
