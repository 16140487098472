import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks

// Styled Elements
import { TableButtonWrapper, TableRowWrapper, TableRowContainer, TableText } from './UsersTable.elements'

// Views
import { TableItem } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function UsersTable(props) {
  // Destructure
  const { data } = props
  // const { showAlert } = actions
  const { email, name, permissions } = data

  // States

  // function formatValue(number) {
  //   if (number < 1_000_000) {
  //     return numberWithCommas(Math.round(number * 100) / 100)
  //   }
  //   return compactNumberFormatter.format(number)
  // }

  // Functions
  // UseEffect
  return (
    <TableItem>
      <TableButtonWrapper>
        <TableRowWrapper style={{ gridTemplateColumns: '1fr 1fr 2fr' }}>
          <TableRowContainer>
            <TableText>{email && email}</TableText>
          </TableRowContainer>
          <TableRowContainer>
            <TableText>{name && name}</TableText>
          </TableRowContainer>
          <TableRowContainer>
            <TableText>{permissions && permissions}</TableText>
          </TableRowContainer>
        </TableRowWrapper>
      </TableButtonWrapper>
    </TableItem>
  )
}

// Default Props
UsersTable.defaultProps = {
  address: {},
  actions: {},
  data: {
    email: '',
    name: '',
    permissions: [],
  },
}

// Proptypes Validation
UsersTable.propTypes = {
  address: PropTypes.shape({
    addressLinkDetails: PropTypes.shape({
      address_id: PropTypes.string,
    }),
    addressLinkData: PropTypes.shape({}),
  }),
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
    setAddressLinkDetails: PropTypes.func,
  }),
  data: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    permissions: PropTypes.string,
    status_name: PropTypes.string,
  }),
}
export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)
